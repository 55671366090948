// TODO: Add image alt tags.
/* eslint-disable jsx-a11y/alt-text */
import { useRouter } from 'next/router';
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Trans } from 'react-i18next';
import { PageComponent } from '@root/pages/Type';
// import { useSelector } from 'react-redux';
// import { RootState } from '@root/store/types';
import classnames from 'classnames';
import Link from '../Link';
import styles from './Sidebar.module.scss';
import { useLayout } from '../layouts/LayoutProvider';
import OrderSvg from '@static/img/icons/navigation/order.svg';
import ServicesSvg from '@static/img/icons/navigation/services.svg';
import PaymentsSvg from '@static/img/icons/navigation/payments.svg';
import PartnetshipSvg from '@static/img/icons/navigation/partnetship.svg';
import AccountSvg from '@static/img/icons/navigation/account.svg';
import HelpSvg from '@static/img/icons/navigation/help.svg';
import { useService } from './ServiceUtils';

const groupClassMap = {
    ui_order: ['ui_order'],
    ui_services: [
        'ui_services_list',
        
        'ui_services_mass_renew',
        'ui_services_mass_renew_success',
        'ui_services_mass_renew_cancel',
    ],
    ui_services_control: [
        'ui_services_detail',
        'ui_services_graphs',
        'ui_services_resources',
        'ui_services_os',
        'ui_services_backups',
        'ui_services_renew',
        'ui_services_transfer',
        'ui_services_management_history',
    ],
    ui_services_settings: [
        'ui_services_settings',
        'ui_services_settings_hostname',
        'ui_services_settings_root_password',
        'ui_services_settings_ns',
        'ui_services_settings_rdns',
        'ui_services_settings_server_title',
        'ui_services_settings_iptables',
        'ui_services_settings_extra',
        'ui_services_settings_ipv6',
        'ui_services_settings_ssh_key'
    ],
    ui_help: ['ui_duk'],
    ui_partnership: [
        'ui_partnership_banners',
        'ui_partnership_coupons',
        'ui_partnership_referrals',
    ],
    ui_account: [
        'ui_account_profile',
        'ui_account_password_change',
        'ui_account_email_change',
        'ui_account_notifications',
        'ui_account_login_history',
        'ui_account_service_management_history',
        'ui_account_twoauth',
        'ui_account_social_accounts',
        'ui_account_invoices_mails',
        'ui_account_ssh_keys',
    ],
    ui_payments: [
        'ui_payments_topup',
        'ui_payments_invoices',
        'ui_payments_macro_system',
        'ui_payments_micro_system',
        'ui_payments_history',
    ],
};

const fullSidebarMap = [
    ...groupClassMap.ui_services_control,
    ...groupClassMap.ui_services_settings,
    ...groupClassMap.ui_help,
    ...groupClassMap.ui_partnership,
    ...groupClassMap.ui_account,
    ...groupClassMap.ui_payments,
];

type SidebarProps = {
    nextRoute: string;
    serviceId: string;
};

const Sidebar: PageComponent<SidebarProps> = ({ nextRoute, serviceId }) => {
    const routerHook = useRouter();
    // const refIncomeCount = useSelector(
    //     (state: RootState) => state.auth.user?.refIncomeCount,
    // );
    const service = useService({
        initialOrderno: serviceId != '-' ? serviceId : null,
    });
    const { sidebarVisible, setSidebarVisible } = useLayout();

    const activeSidebarItemGroup = (...items: string[]): string => {
        for (let item of items) {
            if (
                groupClassMap[item].indexOf(routerHook.query.nextRoute) !== -1
            ) {
                return styles.active;
            }
        }
        return '';
    };

    const activeSidebarItem = (item: string): string => {
        return routerHook.query.nextRoute === item ? styles.active : '';
    };

    return (
        <>
            <Helmet>
                <body
                    data-sidebar-full={
                        nextRoute && fullSidebarMap.indexOf(nextRoute) !== -1
                            ? 'true'
                            : 'false'
                    }
                />
            </Helmet>
            <div
                className={classnames(styles.sidebar, {
                    [styles.active]: sidebarVisible,
                })}
            >
                <ul>
                    <li className={activeSidebarItemGroup('ui_order')}>
                        <Link href="ui_order">
                            <a
                                onClick={() => {
                                    if (
                                        routerHook.query.nextRoute == 'ui_order'
                                    ) {
                                        setSidebarVisible(false);
                                    }
                                }}
                            >
                                <OrderSvg />
                                <span>
                                    <Trans
                                        i18nKey="sidebar.order"
                                        ns="uiComponent"
                                    >
                                        Užsakymas
                                    </Trans>
                                </span>
                            </a>
                        </Link>
                    </li>

                    <li
                        className={activeSidebarItemGroup(
                            'ui_services',
                            'ui_services_control',
                            'ui_services_settings',
                        )}
                    >
                        <Link href="ui_services_list">
                            <a
                                onClick={() => {
                                    if (
                                        routerHook.query.nextRoute ==
                                        'ui_services_list'
                                    ) {
                                        setSidebarVisible(false);
                                    }
                                }}
                            >
                                <ServicesSvg />
                                <span>
                                    <Trans
                                        i18nKey="sidebar.services.title"
                                        ns="uiComponent"
                                    >
                                        Paslaugos
                                    </Trans>
                                </span>
                            </a>
                        </Link>
                        <ul className="list-unstyled">
                            <li className={styles.serverTitle}>
                                {service.data?.title}
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_services_detail',
                                )}
                            >
                                <Link
                                    href="ui_services_detail"
                                    params={{ id: serviceId }}
                                >
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_services_detail'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.services.details"
                                                ns="uiComponent"
                                            >
                                                Serverio informacija
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            {!service.isRequesting &&
                                service.data?.type === 'vps' && (
                                    <li
                                        className={activeSidebarItem(
                                            'ui_services_graphs',
                                        )}
                                    >
                                        <Link
                                            href="ui_services_graphs"
                                            params={{ id: serviceId }}
                                        >
                                            <a
                                                onClick={() => {
                                                    if (
                                                        routerHook.query
                                                            .nextRoute ==
                                                        'ui_services_graphs'
                                                    ) {
                                                        setSidebarVisible(
                                                            false,
                                                        );
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <Trans
                                                        i18nKey="sidebar.services.graphs"
                                                        ns="uiComponent"
                                                    >
                                                        Grafikai
                                                    </Trans>
                                                </span>
                                            </a>
                                        </Link>
                                    </li>
                                )}
                            {!service.isRequesting &&
                                service.data?.type === 'vps' && (
                                    <li
                                        className={activeSidebarItem(
                                            'ui_services_resources',
                                        )}
                                    >
                                        <Link
                                            href="ui_services_resources"
                                            params={{ id: serviceId }}
                                        >
                                            <a
                                                onClick={() => {
                                                    if (
                                                        routerHook.query
                                                            .nextRoute ==
                                                        'ui_services_resources'
                                                    ) {
                                                        setSidebarVisible(
                                                            false,
                                                        );
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <Trans
                                                        i18nKey="sidebar.services.resourceChange"
                                                        ns="uiComponent"
                                                    >
                                                        Resursų keitimas
                                                    </Trans>
                                                </span>
                                            </a>
                                        </Link>
                                    </li>
                                )}
                            {false && (
                                <li className="">
                                    <a href="/ui/paslaugos/one-click-instaliacija.html">
                                        <span>One click instaliacija -</span>
                                    </a>
                                </li>
                            )}
                            {!service.isRequesting &&
                                service.data?.type === 'vps' && (
                                    <li
                                        className={activeSidebarItem(
                                            'ui_services_os',
                                        )}
                                    >
                                        <Link
                                            href="ui_services_os"
                                            params={{ id: serviceId }}
                                        >
                                            <a
                                                onClick={() => {
                                                    if (
                                                        routerHook.query
                                                            .nextRoute ==
                                                        'ui_services_os'
                                                    ) {
                                                        setSidebarVisible(
                                                            false,
                                                        );
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <Trans
                                                        i18nKey="sidebar.services.osChange"
                                                        ns="uiComponent"
                                                    >
                                                        OS perrašymas
                                                    </Trans>
                                                </span>
                                            </a>
                                        </Link>
                                    </li>
                                )}
                            <li
                                className={
                                    activeSidebarItem('ui_services_settings') ||
                                    activeSidebarItemGroup(
                                        'ui_services_settings',
                                    )
                                }
                            >
                                <Link
                                    href="ui_services_settings"
                                    params={{ id: serviceId }}
                                >
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_services_settings'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.services.settings"
                                                ns="uiComponent"
                                            >
                                                Nustatymai
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            {!service.isRequesting &&
                                service.data?.type !== 'ds' && (
                                    <li
                                        className={activeSidebarItem(
                                            'ui_services_backups',
                                        )}
                                    >
                                        <Link
                                            href="ui_services_backups"
                                            params={{ id: serviceId }}
                                        >
                                            <a
                                                onClick={() => {
                                                    if (
                                                        routerHook.query
                                                            .nextRoute ==
                                                        'ui_services_backups'
                                                    ) {
                                                        setSidebarVisible(
                                                            false,
                                                        );
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <Trans
                                                        i18nKey="sidebar.services.backups"
                                                        ns="uiComponent"
                                                    >
                                                        Backupai
                                                    </Trans>
                                                </span>
                                            </a>
                                        </Link>
                                    </li>
                                )}
                            {false && (
                                <li>
                                    <a href="/ui/paslaugos/licenzijos.html">
                                        <span>Licenzijos -</span>
                                    </a>
                                </li>
                            )}
                            <li
                                className={activeSidebarItem(
                                    'ui_services_renew',
                                )}
                            >
                                <Link
                                    href="ui_services_renew"
                                    params={{ id: serviceId }}
                                >
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_services_renew'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.services.renew"
                                                ns="uiComponent"
                                            >
                                                Paslaugos pratęsimas
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_services_transfer',
                                )}
                            >
                                <Link
                                    href="ui_services_transfer"
                                    params={{ id: serviceId }}
                                >
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_services_transfer'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.services.transfer"
                                                ns="uiComponent"
                                            >
                                                Paslaugos perleidimas
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_services_management_history',
                                )}
                            >
                                <Link
                                    href="ui_services_management_history"
                                    params={{ id: serviceId }}
                                >
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_services_management_history'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.services.managementHistory"
                                                ns="uiComponent"
                                            >
                                                Paslaugos valdymo istorija
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            {false && (
                                <li className="">
                                    <a href="/ui/paslaugos/antiddos-apsauga.html">
                                        <span>AntiDDoS apsauga</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </li>

                    {false && (
                        <li className="disabled">
                            <a href="#">
                                <img src="/static/img/icons/navigation/settings-disabled.svg" />
                                <span>Nustatymai</span>
                            </a>
                        </li>
                    )}
                    <li className={activeSidebarItemGroup('ui_payments')}>
                        <Link href="ui_payments_topup">
                            <a
                                onClick={() => {
                                    if (
                                        routerHook.query.nextRoute ==
                                        'ui_payments_topup'
                                    ) {
                                        setSidebarVisible(false);
                                    }
                                }}
                            >
                                <PaymentsSvg />
                                <span>
                                    <Trans
                                        i18nKey="sidebar.payments.title"
                                        ns="uiComponent"
                                    >
                                        Atsiskaitymas
                                    </Trans>
                                </span>
                            </a>
                        </Link>

                        <ul className="list-unstyled">
                            <li
                                className={activeSidebarItem(
                                    'ui_payments_topup',
                                )}
                            >
                                <Link href="ui_payments_topup">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_payments_topup'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.payments.topup"
                                                ns="uiComponent"
                                            >
                                                Papildymas
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_payments_invoices',
                                )}
                            >
                                <Link href="ui_payments_invoices">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_payments_invoices'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.payments.invoices"
                                                ns="uiComponent"
                                            >
                                                PVM Sąskaitos-faktūros
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_payments_macro_system',
                                )}
                            >
                                <Link href="ui_payments_macro_system">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_payments_macro_system'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.payments.macroSystem"
                                                ns="uiComponent"
                                            >
                                                Automatinių mokėjimų sistema
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_payments_micro_system',
                                )}
                            >
                                <Link href="ui_payments_micro_system">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_payments_micro_system'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.payments.microSystem"
                                                ns="uiComponent"
                                            >
                                                SMS mokėjimų sistema
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_payments_history',
                                )}
                            >
                                <Link href="ui_payments_history">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_payments_history'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.payments.history"
                                                ns="uiComponent"
                                            >
                                                Istorija
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeSidebarItemGroup('ui_account')}>
                        <Link href="ui_account_profile">
                            <a
                                onClick={() => {
                                    if (
                                        routerHook.query.nextRoute ==
                                        'ui_account_profile'
                                    ) {
                                        setSidebarVisible(false);
                                    }
                                }}
                            >
                                <AccountSvg />
                                <span>
                                    <Trans
                                        i18nKey="sidebar.account.title"
                                        ns="uiComponent"
                                    >
                                        Paskyra
                                    </Trans>
                                </span>
                            </a>
                        </Link>

                        <ul className="list-unstyled">
                            <li
                                className={activeSidebarItem(
                                    'ui_account_profile',
                                )}
                            >
                                <Link href="ui_account_profile">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_profile'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.profile"
                                                ns="uiComponent"
                                            >
                                                Profilis
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_account_twoauth',
                                )}
                            >
                                <Link href="ui_account_twoauth">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_twoauth'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.twoauth"
                                                ns="uiComponent"
                                            >
                                                2-FA
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_account_password_change',
                                )}
                            >
                                <Link href="ui_account_password_change">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_password_change'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.passwordChange"
                                                ns="uiComponent"
                                            >
                                                Slaptažodžio keitimas
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_account_email_change',
                                )}
                            >
                                <Link href="ui_account_email_change">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_email_change'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.emailChange"
                                                ns="uiComponent"
                                            >
                                                El. pašto keitimas
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_account_invoices_mails',
                                )}
                            >
                                <Link href="ui_account_invoices_mails">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_invoices_mails'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.invoicesMails"
                                                ns="uiComponent"
                                            >
                                                Saskaitu pasto dezutes
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            {false && (
                                <li
                                    className={activeSidebarItem(
                                        'ui_account_notifications',
                                    )}
                                >
                                    <Link href="ui_account_notifications">
                                        <a
                                            onClick={() => {
                                                if (
                                                    routerHook.query
                                                        .nextRoute ==
                                                    'ui_account_notifications'
                                                ) {
                                                    setSidebarVisible(false);
                                                }
                                            }}
                                        >
                                            <span>
                                                <Trans
                                                    i18nKey="sidebar.account.notifications"
                                                    ns="uiComponent"
                                                >
                                                    Pranešimai
                                                </Trans>
                                            </span>
                                        </a>
                                    </Link>
                                </li>
                            )}
                            {false && (
                                <li>
                                    <a href="/ui/paskyra/vartotojai.html">
                                        <span>Vartotojai</span>
                                    </a>
                                </li>
                            )}
                            <li
                                className={activeSidebarItem(
                                    'ui_account_login_history',
                                )}
                            >
                                <Link href="ui_account_login_history">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_login_history'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.loginHistory"
                                                ns="uiComponent"
                                            >
                                                Prisijungimų istorija
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_account_service_management_history',
                                )}
                            >
                                <Link href="ui_account_service_management_history">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_service_management_history'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.serviceManagementHistory"
                                                ns="uiComponent"
                                            >
                                                Paslaugų valdymo istorija
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_account_social_accounts',
                                )}
                            >
                                <Link href="ui_account_social_accounts">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_social_accounts'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.socialAccounts"
                                                ns="uiComponent"
                                            >
                                                Socialines paskyros
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            <li
                                className={activeSidebarItem(
                                    'ui_account_ssh_keys',
                                )}
                            >
                                <Link href="ui_account_ssh_keys">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_account_ssh_keys'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.account.sshKeys"
                                                ns="uiComponent"
                                            >
                                                SSH Raktai
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeSidebarItemGroup('ui_partnership')}>
                        <Link href="ui_partnership_banners">
                            <a
                                onClick={() => {
                                    if (
                                        routerHook.query.nextRoute ==
                                        'ui_partnership_banners'
                                    ) {
                                        setSidebarVisible(false);
                                    }
                                }}
                            >
                                <PartnetshipSvg />
                                <span>
                                    <Trans
                                        i18nKey="sidebar.partnership.title"
                                        ns="uiComponent"
                                    >
                                        Partnerystė
                                    </Trans>
                                </span>
                            </a>
                        </Link>
                        <ul className="list-unstyled">
                            <li
                                className={activeSidebarItem(
                                    'ui_partnership_banners',
                                )}
                            >
                                <Link href="ui_partnership_banners">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_partnership_banners'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.partnership.banners"
                                                ns="uiComponent"
                                            >
                                                Baneriai
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            {/**
                            <li
                                className={activeSidebarItem(
                                    'ui_partnership_coupons',
                                )}
                            >
                                <Link href="ui_partnership_coupons">
                                    <a>
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.partnership.coupons"
                                                ns="uiComponent"
                                            >
                                                Kuponai
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                             */}
                            {/** typeof refIncomeCount !== 'undefined' &&
                                refIncomeCount > 0 && (*/}
                            <li
                                className={activeSidebarItem(
                                    'ui_partnership_referrals',
                                )}
                            >
                                <Link href="ui_partnership_referrals">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_partnership_referrals'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.partnership.referrals"
                                                ns="uiComponent"
                                            >
                                                Referalai
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeSidebarItemGroup('ui_help')}>
                        <Link href="ui_duk">
                            <a
                                onClick={() => {
                                    if (
                                        routerHook.query.nextRoute == 'ui_duk'
                                    ) {
                                        setSidebarVisible(false);
                                    }
                                }}
                            >
                                <HelpSvg />
                                <span>
                                    <Trans
                                        i18nKey="sidebar.help.title"
                                        ns="uiComponent"
                                    >
                                        Pagalba
                                    </Trans>
                                </span>
                            </a>
                        </Link>

                        <ul className="list-unstyled">
                            <li className={activeSidebarItem('ui_duk')}>
                                <Link href="ui_duk">
                                    <a
                                        onClick={() => {
                                            if (
                                                routerHook.query.nextRoute ==
                                                'ui_duk'
                                            ) {
                                                setSidebarVisible(false);
                                            }
                                        }}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="sidebar.help.faq"
                                                ns="uiComponent"
                                            >
                                                D.U.K.
                                            </Trans>
                                        </span>
                                    </a>
                                </Link>
                            </li>
                            {false && (
                                <li>
                                    <a href="uzklausos.html">
                                        <span>Uzklausos</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default memo(Sidebar);
